.runviz-box {
    -webkit-transition: width 1s ease-in-out !important;
    -moz-transition: width 1s ease-in-out !important;
    -o-transition: width 1s ease-in-out !important;
    transition: width 1s ease-in-out !important;
    font-size: 0.8em !important;
    text-shadow: 1px 1px 1px black !important;
    font-weight: bold;
  }

  .gradient-fade {
    position: absolute;
    width: 40px;
    height: 100%;
    pointer-events: none; /* Make sure it doesn't interfere with clicking */
    z-index: 10;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .trophy-icon {
    font-size: 50px; /* Make the trophy icon big */
    color: yellow; /* Make the trophy icon yellow */
    display: flex;
    justify-content: center; /* Center the trophy horizontally */
    margin-bottom: 10px; /* Space between the trophy and the time */
  }
  
  .trophy-icon svg {
    width: 50px;
    height: 50px;
    fill: yellow;
    stroke: yellow; /* To ensure the icon is yellow */
  }

  .trophy-text {
    display: flex;
    justify-content: center; /* Center the trophy horizontally */
  }

  .left-gradient {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, var(--bg-color) 100%);
  }
  .right-gradient {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--bg-color) 100%);
  }

  .chart-container {
    display: flex;
    overflow-x: auto;
  }
  
  .y-axis-container {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 10px;
    background-color: #f8f9fa;
  }
  
  .chart-wrapper {
    flex: 1 1 auto;
    min-width: 600px; /* Adjust as necessary */
  }
  
  .y-axis-label {
    height: 24px; /* Adjust to match chart bar height */
    display: flex;
    align-items: center;
    justify-content: center;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    padding: 4px;
    text-align: center;
  }
  

  :root {
    --bg-color: #ffffff;
    --text-color: #000000;
    --card-bg: #ffffff;
    --border-color: #dee2e6;
  }
  
  .dark-mode {
    --bg-color: #1a1a1a;
    --text-color: #ffffff;
    --card-bg: #2c2c2c;
    --border-color: #444444;
  }

  body {
    background-color: var(--bg-color);
    color: var(--text-color);
  }
  
  .card {
    background-color: var(--card-bg);
    border-color: var(--border-color);
    color: var(--text-color);
  }
  
  .recharts-default-tooltip {
    background-color: var(--card-bg) !important;
    border-color: var(--border-color) !important;
    color: var(--text-color) !important;
  }
  .recharts-tooltip-item {
    color: var(--text-color) !important;
  }

  .text-muted {
    color: var(--text-color) !important;
  }
  
  .runviz-popup {
    background-color: var(--card-bg) !important;
    border-color: var(--border-color) !important;
    color: var(--text-color) !important;
  }

  .table {
    --bs-table-bg: var(--card-bg);
    --bs-table-color: var(--text-color);
    color: var(--text-color);
  }
  
  .table-bordered {
    border-color: var(--border-color);
  }
  
  .modal-content {
    background-color: var(--bg-color);
    color: var(--text-color);
  }
  
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.main-body {
  border-radius: 15px;
  padding: 20px;
  background-color: var(--bg-color) !important;
  box-shadow: 0 0 5px var(--bg-color);
}

body {
  background-size: cover;
  background-attachment: fixed;
}

.highlight {
  border: 2px solid red;
}

.btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

  /* Add more selectors as needed */

  .tooltip-inner {
    max-width: 300px;
}